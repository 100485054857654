export default {
  name: 'TokenInput',
  data: () => ({
  	amount: 0,
    token_prompt: "tokens"
  }),
  watch: {
    amount: (new_amount) => {
      console.log("D: "+new_amount);
      this.token_prompt = new_amount === 1 ? "token" : "tokens";
    }
  },
  render() {
    return(
	    <span>
        <input v-model={this.amount} class="form-control" />
        {this.token_prompt}
      </span>
    );
  }
}