// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import TurbolinksAdapter from 'vue-turbolinks'
import Vue from 'vue'

import "../material-kit/js/core/bootstrap-material-design.min"
import "../material-kit/js/material-kit"
import "../material-kit/scss/material-kit"
import "../stylesheets/landing"
import "@fortawesome/fontawesome-free/js/all";

// Import all the macro components of the application
import * as instances from '../instances'

Vue.use(TurbolinksAdapter)

document.addEventListener('turbolinks:load', () => {
    // Initialize available instances
    Object.keys(instances).forEach((instanceName) => {
        const instance = instances[instanceName]
        const elements = document.querySelectorAll(instance.el)
      
        elements.forEach((element) => {
          const props = JSON.parse(element.getAttribute('data-props'))

          new Vue({
            el: element,
            render: h => h(instance.component, { props })
          })
        })
    });

    // nav-links should scroll into view
    const ELEMENT_ID_SHAPE = /^#(\w[-\w]+)$/;
    const navLinks = new Array(...document.getElementsByClassName('nav-link'));
    
    navLinks.forEach((link) => {
      link.onclick = (event) => {
        const href = link.getAttribute("href") || "";
        const targetId = ELEMENT_ID_SHAPE.exec(href)?.[1];

        if (targetId) {
          event.preventDefault();
          event.stopPropagation();
          document.getElementById(targetId).scrollIntoView( { behavior: "smooth" });
          history.pushState({}, null, window.location.pathname+"#"+targetId);
        }
      }
    })
})