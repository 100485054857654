export default {
  name: 'InviteForm',
  data: () => ({
  	invite_key: "",
  }),
  methods: {
  	joinGame: function() {
  		console.log(this);
  		console.log(this.invite_key + " -- :o");
  	}
  },
  render() {
    return(
  		<form action="/join" method="POST">
  			<div class="form-group">
	    		<input v-model={this.invite_key} class="form-control"/>	
	    	</div>
	  		<button type="submit" v-on:click={this.joinGame} class="btn btn-primary"> join </button>
    	</form>
    );
  }
}