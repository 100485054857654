// Import components
import ProductList from './components/product/index'
import InviteForm from './components/invite_form/invite_form'
import TokenInput from './components/token_input/token_input'

export const ProductListInstance = {
    el: '.vue-products',
    component: ProductList
}

export const InviteFormInstance = {
	el: '.invite-form',
	component: InviteForm
}

export const TokenInputInstance = {
	el: '.token-input',
	component: TokenInput
}