// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/dice-on-blue.jpg");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "footer{display:flex;height:50px;align-items:center;justify-content:center}\n.landing-header{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}\n.other-game--left{border-left:1px solid #d2d2d2}\n.other-game__wrapper{display:flex;justify-content:center}\n.other-game__content--top{margin-top:0}\n.other-game__content--bottom{margin-bottom:0}\n.team-member-card__footer{padding-top:0 !important}\n.trading-experiments-pic{height:100%;width:100%;padding:50px 0 20px}\n.trading-experiments-video{height:380px;width:500px;padding:50px 0 20px}\n.footer{height:90px}\n", "",{"version":3,"sources":["common/footer.scss","landing.scss"],"names":[],"mappings":"AAAA,OACC,YAAa,CACb,WAAY,CACZ,kBAAmB,CACnB,sBAAuB;ACDxB,gBACC,wDAAmD;AACnD,kBAKC,6BAA8B;AAC9B,qBAGA,YAAa,CACb,sBAAuB;AACvB,0BAIC,YAAa;AACb,6BAGA,eAAgB;AAChB,0BAMD,wBAAyB;AACzB,yBAID,WAAY,CACZ,UAAW,CACX,mBAAoB;AACpB,2BAGA,YAAa,CACb,WAAY,CACZ,mBAAoB;AACpB,QAGA,WAAY","file":"landing.scss","sourcesContent":["footer {\n\tdisplay: flex;\n\theight: 50px;\n\talign-items: center;\n\tjustify-content: center;\n}\n","@import 'common/footer';\n@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons');\n\n.landing-header {\n\tbackground-image: url('../images/dice-on-blue.jpg');\n}\n\n.other-game {\n\n\t&--left {\n\t\tborder-left: 1px solid #d2d2d2;\n\t}\n\n\t&__wrapper {\t\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t}\n\n\t&__content {\n\t\t&--top {\n\t\t\tmargin-top: 0;\n\t\t}\n\t\n\t\t&--bottom {\n\t\t\tmargin-bottom: 0;\n\t\t}\n\t}\n}\n\n.team-member-card {\n\t&__footer {\n\t\tpadding-top: 0 !important;\n\t}\n}\n\n.trading-experiments-pic {\n\theight: 100%;\n\twidth: 100%;\n\tpadding: 50px 0 20px;\n}\n\n.trading-experiments-video {\n\theight: 380px;\n\twidth: 500px;\n\tpadding: 50px 0 20px;\n}\n\n.footer { \n\theight: 90px;\n}"]}]);
// Exports
module.exports = exports;
